import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { GrChapterNext, GrChapterPrevious } from "react-icons/gr";
import { IoPause, IoPlay } from "react-icons/io5";
import back1 from "./BACKMONEY/1.webp";
import back2 from "./BACKMONEY/2.webp";
import back3 from "./BACKMONEY/3.webp";
import back4 from "./BACKMONEY/4.webp";
import back5 from "./BACKMONEY/5.webp";
import back6 from "./BACKMONEY/6.webp";
import music1 from "./1.mp3";
import music2 from "./2.mp3";
import music3 from "./3.mp3";
import music4 from "./4.mp3";
import music5 from "./5.mp3";
import music6 from "./6.mp3";

function Page() {
  const [tokenData, setTokenData] = useState(null);
  const [volume, setVolume] = useState(50);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const audioRef = useRef(null);
  const accessKey = 'A1qQaAA9kdfnn4Mmn44bpoieIYHKkdghFKUD1978563llakLLLKdfslphgarcorc3haeogmmMNn243wf';

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await axios.get('https://interca.onrender.com/api/purchaseData', {
          headers: {
            'x-access-key': accessKey,
          },
        });
        setTokenData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados do token:', error);
      }
    };

    fetchTokenData();
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  useEffect(() => {
    document.body.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${back1})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
  }, [currentMusicIndex]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(error => {
        console.error("Erro ao tentar tocar o áudio:", error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const nextMusic = () => {
    setCurrentMusicIndex((prevIndex) => (prevIndex + 1) % musicTracks.length);
    setIsPlaying(true);
  };

  const prevMusic = () => {
    setCurrentMusicIndex((prevIndex) =>
      (prevIndex - 1 + musicTracks.length) % musicTracks.length
    );
    setIsPlaying(true);
  };

  const copyToken = () => {
    navigator.clipboard.writeText(tokenData ? tokenData.tokenCA : "Token indisponível");
    alert("Token copied!");
  };

  const backgrounds = [back1, back2, back3, back4, back5, back6];
  const musicTracks = [music1, music2, music3, music4, music5, music6];
  const musicNames = ["Million Dollar Baby", "BumbleBee", "In my Mind", "Waves", "Psycho", "Together Always"];

  return (
    <div className="Page">
      <div className="links">
        <a className="buy" target="_blank" href={tokenData ? tokenData.link : "#"}>BUY NOW</a>
        <div className="socials">
          <a className="social" target="_blank" href={tokenData ? tokenData.twitterLink : "#"}>Twitter</a>
          <a className="social" target="_blank" href={tokenData ? tokenData.telegramLink : "#"}>Telegram</a>
        </div>
      </div>
      <div className="controls">
        <span className="music-label">{musicNames[currentMusicIndex]}</span>
        <div className="music-controls">
          <button onClick={prevMusic} className="music-button"><GrChapterPrevious size={50} /></button>
          <button onClick={togglePlayPause} className="music-button">
            {isPlaying ? <IoPause size={50} /> : <IoPlay size={50} />}
          </button>
          <button onClick={nextMusic} className="music-button"><GrChapterNext size={50} /></button>
        </div>
        <input
          id="volumeControl"
          type="range"
          min="0"
          max="100"
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
          className="volume-knob"
        />
      </div>
      <div className="envCa">
        <div className="tokenCa" onClick={copyToken}>
          <span className="moneyEmoji">💸</span>
          {tokenData ? tokenData.tokenCA : 'Carregando...'}
          <span className="moneyEmoji">💸</span>
        </div>
      </div>

      <audio ref={audioRef} src={musicTracks[currentMusicIndex]} loop />
    </div>
  );
}

export default Page;
